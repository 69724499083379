<template>
  <div>
    <b-table
      :key="tableKey"
      small
      :fields="fields"
      :items="patientList"
      responsive="sm"
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(status)="data">
        <div v-if="data.value === 0">
          <b-button
            v-b-modal.confrimStatusModal
            variant="warning"
          >
            ยืนยันคำขอ?
          </b-button>
          <b-modal
            id="confrimStatusModal"
            centered
            title="ยืนยันคำขอ"
            auto-focus-button="ok"
            @ok="allowStatus(data.index)"
          >
            <h4>ต้องการยืนยันคำขอหรือไม่?</h4>
          </b-modal>
        </div>
        <div v-else-if="data.value === 1">
          <b-button
            variant="success"
            @click="sendViewer(data.index)"
          >
            เปิดแฟ้มผู้ป่วย
          </b-button>
        </div>
        <div v-else>
          <b-button
            variant="danger"
            disabled
          >
            ยกเลิกคำขอ
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        { key: 'name', label: 'ชื่อ' },
        { key: 'gender', label: 'เพศ' },
        { key: 'idCard', label: 'เลขปปจ' },
        { key: 'phoneNumber', label: 'เลขโทรศัพท์' },
        { key: 'reqNote', label: 'เหตุผลการส่งตัว' },
        { key: 'status', label: 'สถานะ' },
      ],
      patientList: [],
      tableKey: 0,
    }
  },
  created() {
    this.getPatientData()
  },
  methods: {
    async allowStatus(value) {
      await this.$http({
        url: 'http://192.168.100.56/api/PhysicalTherapy/RequestPhysicalTherapy',
        method: 'post',
        data: {
          ReqID: this.patientList[value].reqID,
          ReqStatus: 1,
          ReqNote: this.patientList[value].reqNote,
        },
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
      })
      await this.getPatientData()
      this.tableKey += 1
    },
    async getPatientData() {
      this.patientList = []
      await this.$http({
        url: 'http://192.168.100.56/api/PhysicalTherapy/RequestPhysicalTherapyByFilter',
        method: 'post',
        data: {
          STDateReq: '',
          EDDateReq: '2022-11-07',
          IDCard: '',
        },
      }).then(({ data }) => {
        data.forEach(element => {
          const tempElement = {}
          tempElement.name = `${element.patientInfo.prefix} ${element.patientInfo.firstName} ${element.patientInfo.lastName}`
          tempElement.gender = element.patientInfo.gender
          tempElement.idCard = element.patientInfo.idCard
          tempElement.phoneNumber = element.patientInfo.mainContactNumber
          tempElement.reqNote = element.request_PhysicalTherapy.reqNote
          tempElement.status = element.request_PhysicalTherapy.reqStatus
          tempElement.reqID = element.request_PhysicalTherapy.reqID
          tempElement.hn = element.patientInfo.hn
          this.patientList.push(tempElement)
          console.log(this.patientList)
        })
      })
    },
    async sendViewer(value) {
      const patientData = { hn: this.patientList[value].hn }
      await this.$router.push({ name: 'medicalViewer', params: patientData })
    },
  },
}
</script>
